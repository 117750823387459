import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import styles from './corporate-careers.module.scss';
import HeroImage from '../components/hero-image/hero-image';
import { GatsbyImg } from '../services/img-location/img-location-service';
import CareersOverviewSection from '../components/about/careers/careers-overview-section';
import CareersPerksSection from '../components/about/careers/careers-perks-section';
import CareersGrowthSection from '../components/about/careers/careers-growth-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getCorporateCareersImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const gastbyOGImg = new GatsbyImg('corporate-careers-og', data);
  const gatsbyHeroImg = new GatsbyImg('corporate-careers', data);

  return (
    <>
      <Helmet>
        <title>Corporate careers - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="Whether you're an accountant, human resources professional, marketing guru, sales superstar, technology expert, or just starting out in your career, Trustaff is the place where you can rise to the top of your field."
        />
        <meta property="og:title" content="Join the Trustaff team" />
        <meta
          property="og:description"
          content="Whether you're an accountant, human resources professional, marketing guru, sales superstar, technology expert, or just starting out in your career, Trustaff is the place where you can rise to the top of your field."
        />
        <meta property="og:image" content={gastbyOGImg.ogImgUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/corporate-careers/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage fluid={gatsbyHeroImg.fluidImg}>
          <div className={'columns'}>
            <div className="column is-7 is-offset-1">
              <div className={styles.heroTextArea}>
                <h1 className={`title is-3 has-text-white`}>
                  Corporate careers
                </h1>
                <p className={`content`}>
                  We've been thrilled to experience incredible growth and
                  success over the last 19+ years and we attribute that to the
                  dedicated, talented people who call Trustaff home.
                </p>
                <p className={`content`}>
                  Whether you're an accountant, human resources professional,
                  marketing guru, sales superstar, technology expert, or just
                  starting out in your career, Trustaff is the place where you
                  can rise to the top of your field.
                </p>
                <p className={`content`}>
                  Join a growing, nationally recognized team with an eye for
                  success!
                </p>
              </div>
            </div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <CareersOverviewSection
            fluid={
              getCorporateCareersImageEdge('corporate-careers-sidebar-1').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <CareersPerksSection
            fluid={
              getCorporateCareersImageEdge('corporate-careers-sidebar-1').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <CareersGrowthSection
            fluid={
              getCorporateCareersImageEdge('corporate-careers-sidebar-2').node
                .childImageSharp.fluid
            }
          />
        </div>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^corporate-careers/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
