import React from 'react';
import Img from 'gatsby-image';
import styles from './careers-perks-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Perks of joining the Trustaff team include:
          </h3>
          <div className="content">
            <ul>
              <li>State-of-the-art office facilities and meeting spaces</li>
              <li>Complimentary professional salon</li>
              <li>Complimentary relaxation massage services</li>
              <li>Monthly catered meals</li>
              <li>
                Zen room - Quiet relaxation space, clear your head and refresh
                your spirit
              </li>
              <li>
                Game room - Play is a vital activity for creativity and mental
                health
              </li>
              <li>On-site food and snack bar</li>
              <li>Sports leagues, tournaments, and competitive activities</li>
              <li>
                Employee discount program - save on gym memberships, local
                events, and more
              </li>
              <li>Special events and volunteer opportunities</li>
            </ul>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="Work at Trustaff"
          />
        </div>
      </div>
    </div>
  );
};
