import React from 'react';
import Img from 'gatsby-image';
import styles from './careers-growth-section.module.scss';

export default ({ fluid }) => {
  return (
    <>
      <div className="columns">
        <div
          className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
        >
          <div className="column is-6">
            <h3 className="title is-5 has-text-info">
              Grow your career &amp; make an impact
            </h3>
            <div className="content">
              <p className={`content`}>
                When you invest your time and talent into a company, that
                company should invest some of that energy back into you.
                Trustaff employees are continually encouraged to grow, innovate,
                and pursue excellence.
              </p>
              <p className={`content ${styles.subheader}`}>
                We offer professional and personal resources to all Trustaff
                team members including:
              </p>
              <ul className={'is-size-7'}>
                <li>Professional advancement opportunities</li>
                <li>Corporate training</li>
                <li>Continuing education classes</li>
                <li>Guest speakers and local outreach</li>
                <li>Healthcare scholarship program</li>
              </ul>
            </div>
          </div>
          <div
            className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
          >
            <Img
              fluid={fluid}
              className={styles.aboutImageWrapper}
              alt="Trustaff corporate careers"
            />
          </div>
        </div>
      </div>
      <div className="columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile">
        <a
          href="https://ingenovishealth.wd1.myworkdayjobs.com/Ingenovishealth?CF_EEB_Recruiting_Company_Name_-_RE_Extended=bf0e13e56e41101468a249b38f680001"
          target="_blank"
          rel="noreferrer"
          className={`button is-primary`}
        >
          View open positions
        </a>
      </div>
    </>
  );
};
