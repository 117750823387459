import React from 'react';
import styles from './careers-overview-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            An energetic place to call home
          </h3>
          <div className="content">
            <p className="content">
              Working at Trustaff is all about the people. People who work hard,
              play hard, and who hustle every day to get the job done right. We
              are passionate about the business of putting people to work and it
              shows every day in our teamwork and the quality of our services.
            </p>
            <p className="content">
              We recognize that to be the best employee you can be while you're
              at work, you need the flexibility to balance all the parts of your
              life. The place where you spend so much of your time should feel
              like home.
            </p>
          </div>
        </div>
        <div className={`column is-5 is-offset-1`}>
          <div className={`${styles.embedContainer}`}>
            <iframe
              title="vimeoVideo"
              src="https://player.vimeo.com/video/256442764"
              webkitallowfullscreen
              mozallowfullscreen
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
